<template>
  <div
      class="bing-ticket container is-widescreen"
  >
    <div
        v-if="showGame"
        id="draw-column-ticket"
        class="column tile is-vertical is-parent"
    >
      <div class="tile is-child">
        <last-three-numbers :numbers="lastThree" />
        <div class="bingo-roulette">
          <p
              :class="[
              { 'reveal-number reveal-number-animation': animateTagetNum },
              'number-draw',
            ]"
          >
            {{ targetNum }}
          </p>
        </div>
      </div>
      <div
          id="controls-ticket"
          class="tile is-child"
      />
    </div>

    <div
        v-if="showCodeBar"
        class="notification"
    >
      <div class="columns is-mobile">
        <div class="column has-text-left">
          {{ name }}<span v-if="city">, {{ city }}</span>
        </div>
        <div class="column has-text-right">
          <span class="code">{{ code }}</span>
        </div>
      </div>
    </div>

    <div
        v-if="bingoCombinations.length > 0"
        class="notification has-text-centered"
    >
      <div>
        <b-button
            type="is-danger"
            size="is-large"
            @click="claimPrice"
        >
          HIER DRÜCKEN!!! BINGO
        </b-button><br>
      </div>
    </div>

    <div
        v-if="numbers.length > 0"
        id="numbers-column-ticket"
        class=""
    >
      <ul class="numbers-container background-image noselect">
        <li
            v-for="(n, index) in numbers"
            :id="'number-' + [n.number]"
            :key="n.number"
        >
          <div
              :class="[
              'bingo-field',
              'field-index-' + (index + 1),
              { picked: isSelectedNumber(n.number), joker: index == 12 },
            ]"
              @click="selectNumber(n.number)"
          >
            <div class="bingo-letter">
              {{ n.letter }}
            </div>
            <div class="bingo-number">
              {{ n.number }}
            </div>
          </div>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import LastThreeNumbers from "./LastThreeNumbers.vue";
import axios from "axios";

const serverPrefix = window.location.hostname === 'localhost' || window.location.hostname.indexOf("192.168") > -1 ? `http://${window.location.hostname}:3000` : '';
console.log('serverPrefix', serverPrefix);


export default {
  components: {
    LastThreeNumbers
  },
  props: ["code","readOnly"],
  data() {
    return {
      drawingInProcess: false,
      target: null,
      name: "",
      city: "",
      showCodeBar: true,
      targetNum: null,
      animateTagetNum: false,
      maxNumber: 75,
      numbers: [],
      bingoCombinations: [],
      heartbeat: 0,
      bingo: false,
      showGame: false,
      pickedNumbers: [],
      drawnNumbers: [],
      userSelectedNumbers: [],
    };
  },
  computed: {
    lastThree: function () {
      return this.drawnNumbers.slice().reverse().slice(0, 3);
    },
  },
  watch: {
    userSelectedNumbers: {
      handler: function (val, oldVal) {
        this.numbersMissingToWin();
      },
      deep: true,
    },
  },
  created() {
    console.log('PROPS: CODE', this.code);
    this.init();
    this.getTicket();
  },
  methods: {
    claimTicket: function() {
      this.$socket.client.emit("claimTicket", {
        code: this.code,
        name: this.name,
        city: this.city,
      });
    },
    getTicket: function() {
      if(this.code && this.code.length === 7) {
        axios
            .get(serverPrefix + "/ticket/"+ this.code)
            .then((response) => {
              if(response.data.error) {
                this.$swal("Fehler", response.data.error_text, "error");
                return;
              }
              console.log('Response', response);
              if(response.data.ticket && response.data.ticket.holder && response.data.ticket.holder.name && response.data.ticket.holder.city) {
                this.name = response.data.ticket.holder.name;
                this.city = response.data.ticket.holder.city;
              }
              this.numbers = this.parseNumbers(response.data.ticket.numbers);
              if(response.data.ticket.checkedNumbers) {
                this.userSelectedNumbers = response.data.ticket.checkedNumbers;
              }
              if(!this.readOnly) {
                console.log('claimTicket');
                this.claimTicket()
              }
            })
            .catch((error) => {
              const errorText = error.response;
              this.$swal("Fehler", errorText, "error");
              console.log("errorObj", error);
            });
      }
    },
    claimPrice: function () {
      this.$socket.client.emit("bingo", { button: true, code: this.code, bingoCombinations: this.bingoCombinations, checkedNumbers: this.userSelectedNumbers });
      this.$swal({
        title: "BINGO",
        text: "Warte auf Überprüfung...",
        icon: "question",
        showConfirmButton: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
    },
    sendUpdate: function () {
      this.$socket.client.emit(
          "userSelectedNumbers",
          JSON.stringify({
            code: this.code,
            checkedNumbers: this.userSelectedNumbers,
          })
      );
    },
    getLetterByNumber: function (n) {
      if (n >= 1 && n <= 15) { return "B"; }
      if (n > 15 && n <= 30) { return "I"; }
      if (n > 30 && n <= 45) { return "N"; }
      if (n > 45 && n <= 60) { return "G"; }
      if (n > 60 && n <= 75) { return "O"; }
    },
    parseNumbers: function (input) {
      let numbers_and_letters = input.map((n, index) => ({
        number: n,
        letter: this.getLetterByNumber(n),
      }));
      numbers_and_letters[12] = {
        number: "Joker",
        letter: "",
      };
      return numbers_and_letters;
    },
    numbersMissingToWin: function () {
      const checkedNumbers = this.numbers.map((n, index) => ({
        number: n.number,
        letter: n.letter,
        isChecked: this.isSelectedNumber(n.number),
      }));

      const possibles = {
        row1: [0, 1, 2, 3, 4],
        row2: [5, 6, 7, 8, 9],
        row3: [10, 11, 13, 14],
        row4: [15, 16, 17, 18, 19],
        row5: [20, 21, 22, 23, 24],
        col1: [0, 5, 10, 15, 20],
        col2: [1, 6, 11, 16, 21],
        col3: [2, 7, 17, 22],
        col4: [3, 8, 13, 18, 23],
        col5: [4, 9, 14, 19, 24],
        x1: [0, 6, 18, 24],
        x2: [4, 8, 16, 20],
      };

      this.bingoCombinations = [];
      for (const [winnerCombination, indexes] of Object.entries(possibles)) {
        const nrOfChecked = indexes.filter((i) => {
          if (checkedNumbers[i]) {
            return checkedNumbers[i].isChecked === true;
          } else {
            return false;
          }
        }).length;

        if (nrOfChecked === indexes.length) {
          console.log("BINGO", winnerCombination);
          this.bingoCombinations.push(winnerCombination);
          if(this.readOnly) {
            return false;
          }
          sounds.pling.stop();
          sounds.winning.stop();
          sounds.winning.play();
        }
      }
      if(this.bingoCombinations.length > 0) {
        this.$socket.client.emit("bingo", { button: false, code: this.code, bingoCombinations: this.bingoCombinations, checkedNumbers: this.userSelectedNumbers });
      }
      //console.log("checkedNumbers", checkedNumbers);
    },
    init: function () {
      this.numbers = [];
      this.bingoCombinations = [];
      this.userSelectedNumbers = [];
      this.drawnNumbers = [];
      this.target = null;
      this.targetNum = null;
      this.name = "";
      this.city = "";
    },
    isSelectedNumber: function (number) {
      return (this.userSelectedNumbers && this.userSelectedNumbers.indexOf(number) > -1);
    },
    isDrawnNumber: function (number) {
      return this.drawnNumbers.indexOf(number) > -1;
    },
    selectNumber: function (selected) {
      if(this.readOnly) {
        return false;
      }
      sounds.pling.play();
      console.log(selected);

      const index = this.userSelectedNumbers.indexOf(selected);

      // is not already picked
      if (index == -1) {
        this.userSelectedNumbers = [...this.userSelectedNumbers, selected];
        // unpick it
      } else {
        // remove from last pickedNumbers
        if (index > -1) {
          this.userSelectedNumbers.splice(index, 1);
        }
      }

      this.sendUpdate();
    },
    reset: function () {
      localStorage.removeItem("userSelectedNumbers");
      this.target = null;
      this.targetNum = null;
      this.userSelectedNumbers = [];
      this.sendUpdate();
      this.numbers = [];
      this.init();
    },
    startDrawing: function (source = null) {
      const availableNumbers = this.numbers.filter(
          (n) => !this.isDrawnNumber(n.number)
      );
      this.target = setInterval(() => {
        const rndDrawIdx = Math.floor(Math.random() * availableNumbers.length);
        const draw = availableNumbers[rndDrawIdx];
        this.targetNum = draw.number;
      }, 50);
    }
  },
};
</script>




<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all.sass";



.background-image:before {
  content: " ";
  position: absolute;
  display: flex;
  left: 5%;
  right: 5%;
  top: 5%;
  bottom: 5%;
  opacity: 0.2;
  background-image: url('/img/background/image-1469204221.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
}


button {
  font-family: "Spicy Rice", fantasy, "google";
}
#numbers-column-ticket {
  padding-top: 1em;
  padding-bottom: 8em;
}
#controls-ticket {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
#controls-ticket > div {
  display: flex;
  align-items: center;
}

.bingo-roulette {
  margin-top: 50px;
}
ul {
  list-style: none;
  padding: 0;
}



.number-draw {
  width: 200px;
  height: 200px;
  margin: 0 auto 20px;
  border-radius: 100px;
  color: #000;
  padding-top: 10px;
  background-color: rgba($dark, 0.3);
  font-size: 110pt;
  display: flex;
  align-items: center;
  justify-content: center;
}
.numbers-container {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 auto;
  padding: 10px;
  box-shadow: 5px 5px 20px rgba($dark, 0.3);
  width: 100%;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.numbers-container > li {
  font-size: 20pt;
  width: 20%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bingo-field {
  font-size: 20pt;
  width: 64px;
  height: 64px;
  border-radius: 10px;
  padding-top: 2px;
  margin: 2px;
  border: 1px solid rgba(#000, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.bingo-number {
  position: absolute;
  font-weight: bold;
  font-size: 1.5em;
  will-change: font-size;
  transition: font-size 0.1s ease-out;
}
.joker .bingo-number {
  font-size: 0.8em;
  transform: rotate(45deg);
  font-family: "Spicy Rice", Arial, Avenir, sans-serif;
  will-change: transform;
  transition: transform 0.8s ease-in-out;
}
.joker .bingo-number.rotate {
  transform: rotate(321deg);
}

.bingo-letter {
  position: absolute;
  color: #e5d5b3;
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 2px 2px 5px #bebebe;
}
.picked .bingo-letter {
  opacity: 0.7;
}

.picked.bingo-field {
  position: relative;
}

.picked.bingo-field::before {
  position: absolute;
  content: "";
  left: -40%;
  top: calc(50% - 5px);
  width: 170%;
  border-top: 10px solid rgba(255, 0, 0, 0.4);
  border-radius: 10px;
  z-index: 100;
  transform: rotate(-45deg);
}
.picked.bingo-field::after {
  position: absolute;
  content: "";
  left: -30%;
  top: calc(50% - 5px);
  width: 160%;
  border-top: 10px solid rgba(255, 0, 0, 0.4);
  border-radius: 10px;
  transform: rotate(45deg);
}

.field-index-1.picked.bingo-field::before,
.field-index-8.picked.bingo-field::before,
.field-index-5.picked.bingo-field::before,
.field-index-15.picked.bingo-field::before,
.field-index-21.picked.bingo-field::before {
  transform: rotate(-20deg);
  border-top: 12px solid rgba(255, 0, 0, 0.5);
}
.field-index-2.picked.bingo-field::before,
.field-index-7.picked.bingo-field::before,
.field-index-12.picked.bingo-field::before,
.field-index-14.picked.bingo-field::before,
.field-index-16.picked.bingo-field::before {
  transform: rotate(-30deg);
}
.field-index-3.picked.bingo-field::before,
.field-index-5.picked.bingo-field::before {
  transform: rotate(-28deg);
  border-top: 9px solid rgba(255, 0, 0, 0.3);
}

.field-index-1.picked.bingo-field::after {
  transform: rotate(60deg);
}
.field-index-2.picked.bingo-field::after {
  transform: rotate(50deg);
}
.field-index-24.picked.bingo-field::after {
  transform: rotate(74deg);
  border-top: 12px solid rgba(255, 0, 0, 0.5);
  width: 140%;
}
.field-index-25.picked.bingo-field::after {
  transform: rotate(83deg);
  width: 130%;
}

.picked .bingo-number {
  font-size: 2em;
}

.picked.joker .bingo-number {
  font-size: 1em;
}

.picked-number-enter-active {
  animation: bounce-in 1s;
}

.code {
  font-family: monospace;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
    background-color: $dark;
    border: 10px rgba($dark, 0.5) solid;
  }
  50% {
    transform: scale(1.4);
    border: 10px rgba($dark, 0.5) solid;
  }
  100% {
    transform: scale(1);
  }
}

.reveal-number-animation {
  animation: reveal-number 1s;
}

@keyframes reveal-number {
  0% {
    opacity: 0;
    transform: scale(0.8);
    background-color: rgba($primary, 1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
</style>
