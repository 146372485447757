<template>
  <div class="home">
    <br>
    <div>
      <b-button @click="getPlayers"><b-icon icon="refresh"></b-icon></b-button>

      <b-dropdown aria-role="list">
        <template #trigger="{ active }">
          <b-button
              :label="'Sortieren: '+ sortBy"
              icon-left="sort"
              :icon-right="active ? 'menu-up' : 'menu-down'" />
        </template>
        <b-dropdown-item @click="sortTickets('activity')" aria-role="listitem">Letzte Aktivität</b-dropdown-item>
        <b-dropdown-item @click="sortTickets('A-Z')" aria-role="listitem">A-Z</b-dropdown-item>
        <b-dropdown-item @click="sortTickets('Z-A')" aria-role="listitem">Z-A</b-dropdown-item>
      </b-dropdown>

      Mitspieler*innen: {{players.length}}
    </div>
    <b-tabs type="is-toggle" v-model="activeTab" :multiline="true">
      <!--<b-tab-item icon="cog">
        <Controls />
      </b-tab-item>-->
      <b-tab-item label="Numbers">
        <BingoBoard theme="basic" v-bind:accessCode="$route.params.code" />
      </b-tab-item>
      <template v-for="player in ticketTabs">
        <b-tab-item>
          <template #header>
            <b-tag rounded> {{player.checkedNumbers.length}} </b-tag>
            <div v-bind:title="player.code">
              {{ player.name }}<span v-if="player.city">, {{ player.city}}</span>
              <span v-if="!player.name && !player.city">{{player.code}}</span>
            </div>
          </template>
          <!--<div class="buttons">
            <b-button type="is-danger"  @click="removePlayer(player)"><b-icon icon="delete"></b-icon></b-button>
            <b-button @click="sendLive(player)"><b-icon icon="television"></b-icon></b-button>
          </div>
          -->
          <div class="bing-ticket container is-widescreen">
            Letzte Aktivität: {{player.lastUpdate | moment("HH:MM:ss") }} ({{player.lastUpdate | moment("from", "now") }})
          </div>
          <BingoTicket v-bind:code="player.code" v-bind:key="player.lastUpdate" readOnly="true" />
        </b-tab-item>
      </template>
    </b-tabs>
  </div>

</template>

<script>
// @ is an alias to /src
import BingoTicket from '../components/BingoTicket';
import BingoBoard from '@/components/BingoBoard';
import Controls from '@/components/Controls';

import axios from 'axios';
const serverPrefix = window.location.hostname === 'localhost' || window.location.hostname.indexOf("192.168") > -1 ? `http://${window.location.hostname}:3000` : '';

function sortByName(a, b) {
  const nameA = a.name.toUpperCase(); // Groß-/Kleinschreibung ignorieren
  const nameB = b.name.toUpperCase(); // Groß-/Kleinschreibung ignorieren
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // Namen müssen gleich sein
  return 0;
}
function sortByLastUpdate(a, b) {
  if (a.lastUpdate > b.lastUpdate) {
    return -1;
  }
  if (a.lastUpdate < b.lastUpdate) {
    return 1;
  }
  // Namen müssen gleich sein
  return 0;
}

export default {
  name: 'Board',
  data() {
    return {
      activeTab: 0,
      code: null,
      hiddenPlayers: [],
      sortBy: 'A-Z',
      players: []
    }
  },
  created() {
    this.getPlayers()
  },
  sockets: {
    connect: function () {
      console.log("socket connected on Remote.vue");
      this.$socket.client.emit("claimRemote", {accessCode: this.$route.params.code})
    },
    disconnect: function () {
      console.log("socket disconnected on Remote.vue")
    },
    playerupdate: function(data) {
      console.log('playerupdate', data);
      this.getPlayers()
    },
    resetGame: function(data) {
      console.log('resetGame', data);
      this.getPlayers()
    },
    nowinner: function (data) {
      console.log("NOWINNER", data);

      const combination = data.claim.bingoCombinations.length > 1 ? 'Kombinationen: ' : 'Kombination: '
      const city = data.player.city ? ' aus ' + data.player.city : '';

      this.$swal({
        toast: true,
        title: 'KEIN BINGO',
        html: 'Falsch geklickt:<br/>' +
            'CODE: ' + data.player.code +
            '<br>' +
            data.player.name +
            city +
            '<br>' +
            '<br>' +
            combination +
            data.claim.bingoCombinations.join(" "),
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 8000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('click', this.$swal.close)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
      })
    },
    winner: function (data) {

      const combination = data.claim.bingoCombinations.length > 1 ? 'Kombinationen: ' : 'Kombination: '
      const city = data.player.city ? ' aus ' + data.player.city : '';

      this.$swal({
        toast: true,
        title: data.correctCombinations + 'x BINGO!',
        html: 'Wir haben eine*n Gewinner*in:<br/>' +
            'CODE: ' + data.player.code +'<br>'+
            data.player.name +
            city +
            '<br>' +
            combination +
            data.claim.bingoCombinations.join(" ")
        ,
        position: 'top-end',
        showConfirmButton: false,
        timer: 8000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
      })

      console.log("WINNER", data);
      if(data.claim.button) {
        this.$swal({
          title: data.correctCombinations + 'x BINGO!',
          html: 'Wir haben eine*n Gewinner*in:<br/>' +
              'CODE: ' + data.player.code +
              '<br>'+
              '<br>' +
              data.player.name +
              city +
              '<br>' +
              '<br>' +
              combination +
              data.claim.bingoCombinations.join(" "),
        });
        sounds.applause.play();
      }
    },
    showMessage: function (payload) {
      this.$swal.close();
      this.$swal(payload.swal);
      if(sounds[payload.sound]) {
        sounds[payload.sound].play();
      }
    },
  },
  computed: {
    ticketTabs() {
      console.log('ticketTabs');
      const tabs = [...this.players]

      tabs.sort(sortByName)

      if(this.sortBy === 'Z-A') {
        tabs.reverse();
      }

      if(this.sortBy === 'activity') {
        tabs.sort(sortByLastUpdate);
      }

      console.log('ticketTabs', tabs);

      const filteredTabs = tabs.filter(p => {
        return p.connected === true
      });
      //const filteredTabs = tabs.map((p) => this.hiddenPlayers.indexOf(p.code) > -1)
      console.log('ticketTabs', tabs);
      console.log('filteredTabs', filteredTabs);
      return filteredTabs;
    }
  },
  methods: {
    getPlayers: function() {
      axios
          .get(serverPrefix + "/players.json")
          .then((response) => {
            console.log('Response', response);
            this.players.splice(0,this.players.length);
            this.players.push(... response.data)
          })
          .catch((error) => {
            const errorText = error.response ? error.response : error.error_text;
            this.$swal("Fehler", errorText, "error");
            console.log("errorObj", error);
          });
    },
    sortTickets: function(mode) {
      console.log('sortTickets');
      this.sortBy = mode
    },
    sendLive: function(player) {
      console.log('sendLive', player)
    },
    removePlayer: function(player) {
      console.log('removePlayer', player);
      this.hiddenPlayers.push(player.code)
    }
  },
  components: {
    BingoTicket,
    BingoBoard,
    Controls
  }
}
</script>


<style>

#app {
  font-family: Arial, Helvetica, sans-serif;
}

.b-tabs .tag {
  margin-right: 10px;
}
.b-tabs .tab-content {
  background: linear-gradient(
      to bottom,
      rgba(255, 1, 252,0.2),
      transparent 70%
  );;
}

#main-game .bingo-number {
  border: 1px solid !important;
}

</style>
