import {Howl} from 'howler';

export const registerSounds = function() {

    window.sounds = {};

    window.sounds.freeTicket = new Howl({
        src: ['/sounds/mixkit-correct-answer-reward-952.wav'],
        autoplay: false,
        loop: false,
        volume: 1,
    });


    window.sounds.pling = new Howl({
        src: ['/sounds/mixkit-achievement-bell-600.wav'],
        autoplay: false,
        loop: false,
        volume: 1,
    });

    window.sounds.fail = new Howl({
        src: ['/sounds/mixkit-system-beep-buzzer-fail-2964.wav'],
        autoplay: false,
        loop: false,
        volume: 1,
    });

    window.sounds.applause = new Howl({
        src: ['/sounds/mixkit-small-group-cheer-and-applause-518.wav'],
        autoplay: false,
        loop: false,
        volume: 1,
    });

    window.sounds.winning = new Howl({
        src: ['/sounds/mixkit-winning-notification-2018.wav'],
        autoplay: false,
        loop: false,
        volume: 1,
    });

}
