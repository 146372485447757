<template>

  <div class="notification has-text-right">
    <div class="has-text-left">
      <b-field>
        <form
          autocomplete="off"
          @submit.prevent="checkTicketCode"
        >
          <b-input
            icon="barcode"
            size="is-large"
            placeholder="BINGO!-LOS-Code"
            :icon-right="code ? 'close-circle' : ''"
            icon-right-clickable
            :value="code"
            maxlength="7"
            @input="(value, event) => codeInputchange(value, event)"
            @icon-right-click="clearCodeInput"
          />
        </form>
      </b-field>
    </div>

    <b-button
      v-if="showCheckButton"
      rounded
      class="has-background-success has-text-white"
      size="is-large"
      style="margin-top: 1em"
      @click="checkTicketCode"
    >
      Code einlösen
    </b-button>
    <!--
    <b-button
        rounded
        @click="reset"
        v-if="!code"
      >
        Reset
      </b-button>
    -->
  </div>

</template>

<script>
export default {
    name: "CodeInput",
    props: ["code"],
    created() {
        console.log(this.code);
    },
    computed: {
        showCheckButton: function () {
         if(this.code && (this.code.length >= 7 && (this.code[3]=== "–" || this.code[3]=== "-"))) {
             return true;
         } else {
             return false;
         }
        }
    },
    methods: {
        checkTicketCode: function () {
            // removing quick fix for double -
            if(this.code) {
                this.$emit("checkCode", this.code.replace("–", "-"));
            }
            return false;
        },
        codeInputchange: function (value, event) {
            if (value) {
                value = value.toUpperCase();
            }
            if (value[3] === "-" && value[4] === "-") {
                console.log("DOUBLE - :::", value, value.substr(0, 4));
                value = value.substr(0, 3) + "–";
            }
            if (value.length === 3) {
                value += "-";
            }
            this.$emit("update:code", value);
        },
        clearCodeInput: function () {
            this.codeInputchange("");
        },
    }
};
</script>

<style scoped>

</style>
