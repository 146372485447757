<template>
  <div class="notification has-text-centered">
    <b-button
      rounded
      :disabled="isSpinning"
      size="is-medium"
      @click="onButtonClick"
    >
      Jetzt GRATIS-Los sichern!
    </b-button>
  </div>
</template>

<script>
import axios from "axios";

const generateId = () => {
    let POOL = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";
    let id = "";
    for (let i = 0; i < 3; i++) {
        id += POOL.charAt(Math.floor(Math.random() * POOL.length));
    }
    id += "-";
    for (let i = 0; i < 3; i++) {
        id += POOL.charAt(Math.floor(Math.random() * POOL.length));
    }
    return id;
};

const serverPrefix = window.location.hostname === 'localhost' || window.location.hostname.indexOf("192.168") > -1 ? `http://${window.location.hostname}:3000` : '';
console.log('serverPrefix', serverPrefix);
console.log('BASE_URL',process.env.BASE_URL);


export default {
    data() {
        return {
            isSpinning: false,
        };
    },
    methods: {
        onButtonClick() {
            sounds.freeTicket.play();
            this.isSpinning = true;
            axios
                .get(serverPrefix + "/createTicket.json")
                .then((response) => {
                    console.log(response.data.code);
                    this.animateCode(response.data.code);
                })
                .catch((error) => {
                    const errorText = error.response ? error.response : error;
                    this.$swal("Fehler", errorText, "error");
                    console.log("errorObj", error);
                });
        },
        animateCode(finalCode) {
            const randomInterval = setInterval(() => {
                const code = generateId();
                this.$emit("setCode", { code: code, isFinal: false });
            }, 50);
            this.isSpinning = true;
            setTimeout(() => clearInterval(randomInterval), 700);
            setTimeout(() => {
                this.$emit("setCode", { code: finalCode, isFinal: true });
                this.isSpinning = false;
            }, 750);
        },
    },
};
</script>


<style scoped>
button {
    font-family: "Spicy Rice", fantasy, "google";
    background: red;
    color: white;
}

</style>
