import { render, staticRenderFns } from "./BingoGratis.vue?vue&type=template&id=a4e44876&scoped=true&"
import script from "./BingoGratis.vue?vue&type=script&lang=js&"
export * from "./BingoGratis.vue?vue&type=script&lang=js&"
import style0 from "./BingoGratis.vue?vue&type=style&index=0&id=a4e44876&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4e44876",
  null
  
)

export default component.exports