import Vue from 'vue'
import VueRouter from 'vue-router'
import VueSocketIOExt from 'vue-socket.io-extended';
import Buefy from 'buefy'
import VueSweetalert2 from 'vue-sweetalert2';
const io = require('socket.io-client')
const moment  = require('moment');
require('moment/locale/de');
const VueMoment = require('vue-moment')

import Ticket from '@/views/Ticket';
import Home from '@/views/Home';
import Remote from '@/views/Remote';

import 'sweetalert2/dist/sweetalert2.min.css';
import '../styles/styles.scss'

import {registerSounds} from '@/sounds';
registerSounds();

window.env = process.env.NODE_ENV;
const socket = io(window.env === 'production' ? '' : `ws://${window.location.hostname}:3000`);

Vue.use(VueSweetalert2);
Vue.use(Buefy)
Vue.use(VueSocketIOExt, socket);
Vue.use(VueRouter)
Vue.use(VueMoment, {moment});


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ticket/:code',
    name: 'Ticket',
    component: Ticket
  },
  {
    path: '/board',
    name: 'Board',
    component: () => import(/* webpackChunkName: "board" */ '../views/Board.vue')
  },
  {
    path: '/remote/:code',
    name: 'Remote',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Remote
  }
]



const router = new VueRouter({
  routes
})

export default router
