<template>
  <div
      id="controls"
      class="tile is-child"
  >
    <div>
      <b-button
          type="is-primary"
          size="is-medium"
          @click="setTheme('basic')"
      >
        Basic
      </b-button>
      <b-button
          type="is-primary"
          size="is-medium"
          @click="setTheme('one')"
      >
        One
      </b-button>
      <b-button
          type="is-primary"
          size="is-medium"
          @click="setTheme('two')"
      >
        Two
      </b-button>
      <b-button
          type="is-primary"
          size="is-medium"
          @click="setTheme('three')"
      >
        Three
      </b-button>
    </div>
  </div>

</template>

<script>
export default {
  name: "Controls",
  methods: {
    setTheme: function(value) {
      console.log(value)
    }
  }
};
</script>

<style scoped>

</style>
