<template>
  <div
    id="main-game"
    :class="theme"
  >

    <div id="controls" v-if="!showLogo">
      <b-button icon-left="refresh" @click="reset">Reset</b-button>
      <b-button
          :disabled="drawingInProcess"
          icon-left="reload"
          @click="spin"
          @keyup.enter="spin"
      >
        Draw a number
      </b-button>

    </div>

    <div id="logo-wrap" v-if="showLogo">
      <img
        src="/img/logos/TELE-Bingo_Logo_02.png"
        width="400px"
      >
    </div>
    <div
      id="numbers-column"
      class="tile"
    >
      <ul class="numbers-container">
        <li
          v-for="n in numbers"
          :id="[n.number]"
          :key="n.number"
          @click="pickNumber"
        >
          <transition name="picked-number">
            <div
              v-if="isPickedNumber(n.number)"
              :class="['bingo-number', { picked: isPickedNumber(n.number) }]"
            >
              {{ n.number }}
            </div>
          </transition>
          <div
            v-if="!isPickedNumber(n.number)"
            class="bingo-number"
          >
            {{ n.number }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  sockets: {
    connect: function () {
      console.log("socket connected");
    },
    heartbeat: function (data) {
      console.log("heartbeat", data);
      this.heartbeat = data.date;
    },
    startDrawing: function (data) {
      console.log("startDrawing", data);
      this.startDrawing("socket");
    },
    setTheme: function (data) {
      console.log("received: setTheme", data);
      this.theme = data;
    },
    pickedNumbers: function (data) {
      console.log("pickedNumbers", data);
      this.pickedNumbers = data;
      let lastItem = this.pickedNumbers[this.pickedNumbers.length - 1];
      this.targetNum = lastItem;
      this.stopDrawing("socket");
      this.$swal({
        toast: true,
        title: 'Nummer Gezogen: ' + lastItem,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('click', this.$swal.close)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
      })
    },
  },
  props: ['theme','showLogo','accessCode'],
  data() {
    return {
      drawingInProcess: false,
      target: null,
      targetNum: null,
      animateTagetNum: false,
      maxNumber: 75,
      numbers: [],
      heartbeat: 0,
      sidebar: false,
      pickedNumbers: [],
    };
  },
  watch: {
    pickedNumbers: {
      handler: function (val, oldVal) {
        console.log("pickedNumbers", val);
        localStorage.pickedNumbers = JSON.stringify(val);
      },
      deep: true,
    },
  },
  mounted() {
    if (localStorage.pickedNumbers) {
      this.pickedNumbers = JSON.parse(localStorage.pickedNumbers);
    }
  },
  created() {
    this.init();
    var vm = this;
    window.addEventListener("keyup", function (event) {
      if (event.keyCode == 13) {
        vm.spin();
      }
    });
  },
  methods: {
    sendUpdate: function () {
      this.$socket.client.emit("pickedNumbers", JSON.stringify({numbers: this.pickedNumbers, accessCode: this.accessCode}));
    },
    init: function () {
      if (this.numbers.length === 0) {
        this.numbers = [...Array(this.maxNumber).keys()].map((i) => ({
          number: ++i,
        }));
      }
      if (this.pickedNumbers.length === 0) {
        this.pickedNumbers = [];
      }
      this.target = null;
      this.targetNum = null;
    },
    isPickedNumber: function (number) {
      return this.pickedNumbers.indexOf(number) > -1;
    },
    pickNumber: function (event) {
      if(!this.accessCode) {
        return
      }
      this.targetNum = Number(event.target.innerText);

      const index = this.pickedNumbers.indexOf(this.targetNum);

      // is not already picked
      if (index == -1) {
        this.pickedNumbers = [...this.pickedNumbers, this.targetNum];

        // unpick it
      } else {
        // remove from last pickedNumbers
        if (index > -1) {
          this.pickedNumbers.splice(index, 1);
        }

        // set lastItem to previous number
        let lastItem = this.pickedNumbers[this.pickedNumbers.length - 1];
        this.targetNum = lastItem;
      }

      this.sendUpdate();

      console.log(this);
      console.log();
    },
    spin: function () {
      this.drawingInProcess = true;
      this.startDrawing();
      setTimeout(() => {
        this.stopDrawing();
        this.drawingInProcess = false;
      }, 2000);
    },
    reset: function () {
      if (confirm("Do you want to reset?")) {
        localStorage.removeItem("numbers");
        localStorage.removeItem("pickedNumbers");
        this.target = null;
        this.targetNum = null;
        this.pickedNumbers = [];
        this.$socket.client.emit("pickedNumbers", JSON.stringify({numbers: this.pickedNumbers, accessCode: this.accessCode, reset: true}));
        this.sendUpdate();
        this.numbers = [...Array(this.maxNumber).keys()].map((i) => ({
          number: ++i,
        }));
      }
    },
    startDrawing: function (source = false) {
      const availableNumbers = this.numbers.filter(
        (n) => !this.isPickedNumber(n.number)
      );
      this.target = setInterval(() => {
        const rndDrawIdx = Math.floor(Math.random() * availableNumbers.length);
        const draw = availableNumbers[rndDrawIdx];
        this.targetNum = draw.number;
      }, 50);
      if (source !== "socket") {
        this.$socket.client.emit("startDrawing");
      }
    },
    stopDrawing: function (source = false) {
      clearInterval(this.target);
      this.animateTagetNum = true;
      setTimeout(() => {
        this.animateTagetNum = false;
      }, 500);
      if (source !== "socket") {
        this.pickedNumbers = [...this.pickedNumbers, this.targetNum];
        this.sendUpdate();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";

#heartbeat {
  position: fixed;
  top: 20px;
  left: 80px;
  z-index: 100;
}

#logo-wrap {
  position: absolute;
  left: -32px;
  bottom: 0;
}


.numbers-container {
  margin: 0 0 0 auto;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
  max-width: 1400px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.numbers-container > li {
  font-size: 20pt;
  width: 6.666%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bingo-number {
  font-size: 18pt;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  padding-top: 1px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: rgba(56, 0, 22, 0.9);
}

.three .bingo-number,
.two .bingo-number {
  color: black;
  background: black;
}

.three .bingo-number.picked,
.two .bingo-number.picked,
.picked {
  background: #ff01fc;
  color: white;
  font-weight: bold;
}

.picked-number-enter-active {
  animation: bounce-in 1s;
}



@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
    background-color: $dark;
    border: 10px rgba($dark, 0.5) solid;
  }
  50% {
    transform: scale(1.4);
    border: 10px rgba($dark, 0.5) solid;
  }
  100% {
    transform: scale(1);
  }
}

.reveal-number-animation {
  animation: reveal-number 1s;
}

@keyframes reveal-number {
  0% {
    opacity: 0;
    transform: scale(0.8);
    background-color: rgba($primary, 1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
</style>
