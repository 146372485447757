<template>
  <div id="mainstage">

    <div v-for="ticketCode in tickets" v-bind:key="ticketCode" v-bind:label="ticketCode">
      <BingoTicket v-bind:code="ticketCode" />
    </div>
    <CodeInput v-bind:code.sync="code" v-if="tickets.length < 1" @checkCode="checkCode" />
    <BingoGratis v-if="showFreeTicketButton" @setCode="setCode" />
    <div class="notification has-text-centered is-hidden-mobile is-hidden-tablet-only	z100">
      <b-button
          size="is-medium"
          class="pink"
          @click="onButtonClick"
      >
        Zum LIVE Stream
      </b-button>
    </div>
    <div class="notification has-text-centered is-hidden-desktop z100">
        LIVE Stream (PC/Mac/Tablet):<br>https://bit.ly/3vbcXKP
    </div>

    <div v-if="tickets.length < 1" class="background-placeholder background-img">

    </div>
    <div class="logo-wrap" :class="{ offline: !$socket.connected }"
    />
  </div>
</template>

<script>
import BingoTicket from '@/components/BingoTicket';
import BingoGratis from "@/components/BingoGratis.vue";
import CodeInput from '@/components/CodeInput';


function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function randomElement(inputArray) {
  return inputArray[randomInteger(0,inputArray.length-1)];
}



export default {
  name: "Home",
  components: {
    CodeInput,
    BingoTicket,
    BingoGratis
  },
  data() {
    return {
      connected: false,
      showFreeTicketButton: true,
      code: '',
      name: '',
      city: '',
      tickets: [],
      activeTab: 1,
      snackbar: null
    }
  },
  created() {
    console.log('CREATED', this.tickets);
    this.activeTab = this.tickets.length
  },
  methods: {
    onButtonClick: function () {
      window.location.href = 'https://dringeblieben.de/videos/premiere-tele-bingo-mit-ricardo-m-der-neue-bingo-spa-fur-gan'
    },
    checkCode: function(value) {
      console.log('checkCode', value);
      this.$socket.client.emit('checkTicketCode',this.code);
    },
    askUserData: async function () {
      const result = await this.$swal.queue([
        {
          input: "text",
          inputValue: this.name,
          confirmButtonText: "Weiter &rarr;",
          showCancelButton: false,
          allowOutsideClick: false,
          progressSteps: ["1", "2", "3"],
          title: "Dein Name",
          text: "Wie darf ich Dich nennen?",
        },
        {
          input: "text",
          inputValue: this.city,
          confirmButtonText: "Weiter &rarr;",
          showCancelButton: false,
          allowOutsideClick: false,
          progressSteps: ["1", "2", "3"],
          title: "Ort",
          text: "Wo wohnst du?",
        },
        {
          confirmButtonText: "Aktivieren",
          cancelButtonText: "Abbrechen",
          showCancelButton: true,
          allowOutsideClick: false,
          reverseButtons: true,
          progressSteps: ["1", "2", "3"],
          title: "Ticket aktivieren",
          text: "Ein Code kann nur 1x aktiviert werden.",
        },
      ]);

      if (!result.dismiss) {
        sounds.pling.play();
        console.log("AKTIVIEREN: ", result);
        this.showCodeBar = true;
        this.name = result.value[0];
        this.city = result.value[1];
        this.$socket.client.emit("claimTicket", {
          code: this.code,
          name: result.value[0],
          city: result.value[1],
        });
      }

      if (result.value) {
        let firstline = "!";
        if (result.value[0] && result.value[1]) {
          firstline = ` ${result.value[0]} aus ${result.value[1]}!`;
        }

        const confirmButtonTexts = ['Los geht´s!', 'Ab dafür!', 'Bingo spielen!', 'Play safe!', 'Jawohl!']

        this.$swal.fire({
          title: "Hallöchen"+firstline,
          html: `
            <div class="has-text-left" style="margin-top: 1em">
            zur Tele-BINGO!-Show<br>mit RICARDO M.!<br>
            <br>
            Jetzt geht’s los!<br>
            <br>
            <p>Sobald RICARDO M. im Livestream die Bingo-Zahlen zieht, gleichst Du sie mit den Zahlen auf Deinem Los ab.<br >Bei einer Übereinstimmung tippst Du die Zahl auf Deinem Los an.</p>
            <p>Du hast BINGO!, wenn eine Reihe (5&nbsp;Felder ggf. mit Joker) waagerecht, senkrecht oder diagonal vollständig mit den von RICARDO M. gezogenen Zahlen übereinstimmt.</p>
            <p>Rufe erst laut BINGO! und drücke danach auf den mit BINGO! beschrifteten Knopf hier in der App.</p>
            <p>Die Zahlen werden dann überprüft und bekommst eine Nachricht, ob du auch schnell genug warst.</p>
            <p>Jetzt aber vor allem eines:<br>VIEL SPASS!</p>
            </div>
          `,
          confirmButtonText: randomElement(confirmButtonTexts),
        }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$router.push({ path: 'ticket/' + this.code })
              };
        });
      }
      return result;
    },
    setCode: function (payload) {
      this.code = payload.code;
      this.showFreeTicketButton = !payload.isFinal;
      if(payload.validate) {
        console.log('CHECK !!!');
      }
    },
    notifyOffline: function() {
      this.connected = false;
      this.snackbar = this.$buefy.snackbar.open({
        indefinite: true,
        type: 'is-warning',
        position: 'is-bottom',
        actionText: 'ok',
        message: 'Keine Verbindung zum Server.'
      })
    },
    closeNotify: function() {
      if(this.snackbar) {
        this.snackbar.close();
      }
    }
  },
  sockets: {
    connect: function () {
      console.log("socket connected on App.vue");
      this.closeNotify();
    },
    disconnect: function () {
      console.log("socket disconnected on App.vue")
      this.notifyOffline();
    },
    ticketCheckResult: async function (response) {
      console.log('ticketCheckResult', response);
      if (response.error) {
        this.$swal("Fehler", response.error_text, "error");
      } else {
        this.showFreeTicketButton = false;
        if(response.isPersonalized) {
          this.name = response.holder.name;
          this.city = response.holder.city;
        }
        const res = this.askUserData();
        console.log('userData RES' , res);
      }
    },
    ticketData: function (response) {
      console.log("ticketData", response);
      if (response.error) {
        this.$swal("Fehler", response.error_text, "error");
      } else {
        this.showFreeTicketButton = false;
        if(this.tickets.indexOf(response.ticket.code) === -1) {
          this.tickets.push(response.ticket.code);
          this.activeTab = 0
        }
      }
    },
    heartbeat: function (data) {
      this.heartbeat = data.date;
      if (data.pickedNumbers) {
        this.drawnNumbers = JSON.parse(data.pickedNumbers);
        let lastItem = this.drawnNumbers[this.drawnNumbers.length - 1];
        this.targetNum = lastItem;
      }
    },
    showMessage: function (payload) {
      this.$swal.close();
      this.$swal(payload.swal);
      if(sounds[payload.sound]) {
        sounds[payload.sound].play();
      }
    },
  },

};
</script>




<style>
.z100 {
  z-index: 100;
}

.pink {
  font-family: "Spicy Rice", fantasy, "google";
  background: #FF01FC !important;
}

.logo-wrap {
  width: 25%;
  height: 200px;
  max-height: 12vh;
  position: fixed;
  bottom: 5px;
  left: 0;
  background-image: url("/img/logos/TELE-Bingo_Logo_02.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 100%;
}
.logo-wrap.offline {
  filter: grayscale(100%);
}

.background-placeholder {
  height: 50vh;
  width: 80vw;
  position: fixed;
  left: 10vw;
  bottom: 10vh;
  opacity: 0.3;
}
.background-img {
  background-image: url("/img/background/image-1469204221.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logo {
  margin: 20px;
  width: 150px;
}
.footer {
  flex: 1;
}

#mainstage {
  position: absolute;
  z-index: 100;
  padding: 1rem;
  width: 100%;
  background: linear-gradient(
      to bottom,
      rgba(255, 1, 252,0.2),
      transparent 70%
  );
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.b-tabs .tabs li a:focus,
.tabs li.is-active a {
  color: #ff0000;
  border-bottom-color: #ff0000;
}


</style>
